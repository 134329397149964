import ImgCropping from '@/syslib/imgCropping'

export const imgCroppingHandler = (sourceData, parm) => {
  let canvasDom = getCanvasDom(sourceData)
  let ctx = canvasDom.getContext('2d')
  let imgData = ctx.getImageData(0, 0, canvasDom.width, canvasDom.height)
  let imgCropping = new ImgCropping({
    imgData: imgData.data,
    width: imgData.width,
    height: imgData.height
  })
  imgCropping.compute(parm)
  return createCanvasDomByImgData(cropping(canvasDom, imgCropping.getCanvasParm()))
}

export const createCanvasDomByImgData = (imgData) => {
  let dom = document.createElement('canvas')
  let ctx = dom.getContext('2d')
  dom.width = imgData.width
  dom.height = imgData.height
  ctx.putImageData(imgData, 0, 0)
  return dom
}

export const createCanvasDomByImg = (img) => {
  let canvasDom = document.createElement('canvas')
  let ctx = canvasDom.getContext('2d')
  canvasDom.width = img.width
  canvasDom.height = img.height
  ctx.drawImage(img, 0 ,0)
  return canvasDom
}

export const cropping = (canvasDom, data) => {
  let dom = document.createElement('canvas')
  let ctx = dom.getContext('2d')
  dom.width = data.sourceWidth
  dom.height = data.sourceHeight
  ctx.rotate(data.angle)
  ctx.translate(data.translatePoint[0], data.translatePoint[1])
  ctx.drawImage(canvasDom, data.imgStartPoint[0], data.imgStartPoint[1], data.sourceWidth, data.sourceHeight, 0,  0, data.sourceWidth, data.sourceHeight)
  return ctx.getImageData(data.croppingPoint[0], data.croppingPoint[1], data.width, data.height)
}

export const getCanvasDom = (data) => {
  let canvasDom = null
  if (data instanceof ImageData) {
    canvasDom = createCanvasDomByImgData(data)
  } else if (data instanceof Image) {
    canvasDom = createCanvasDomByImg(data)
  } else if (data instanceof HTMLCanvasElement) {
    canvasDom = data
  } else {
    throw new Error('参数异常')
  }
  return canvasDom
}

<template>
  <div class="test">
    <div class="img-item" v-for="item in imgList" :key="item.id">
      <div style="width:270px;margin-right: 10px;">
        <img style="width:270px;" draggable="false" @click="cj(item)" :src="item.src" v-if="item.src" class="icon">
        <fm-btn @click="dzcj(item)">定值自动裁剪{{item.id}}</fm-btn>
      </div>
      <div class="c-s" :style="item.d" :ref="'canvasDiv' + item.id">
      </div>
    </div>
    <fm-form-dialog
      form-title="自动裁剪"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      label-alone
      :mask-closable="false"
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

import {
  imgCroppingHandler,
  createCanvasDomByImg
} from '@/syslib/imgCroppingHandler'

let ids = '5496,5502,5504,5506,5508,5510,5512,5597,5599,5601,5603,5605,5607,5609,5611,5613,5555,5557,5559,5561,5563,5565,5567,5569,5571,5573,5575,5577,5579,5581,5583,5585,5587,5589,5591,5593,5514,5516,5518,5520,5522,5524,5526,5528,5530,5532,5534,5536,5538,5540,5542,5543,5545,5547,5549,5551'

ids = '5496,5502,5504'

export default {
  data () {
    return {
      openDialog: false,
      chooseData: null,
      imgList: ids.split(',').map(v => {
        return {
          id: Number(v),
          src: null,
          width: null,
          height: null,
          success: false,
          d: {
            'max-height': '0px',
            'min-height': '0px',
          }
        }
      })
    }
  },
  methods: {
    cj (item, parm) {
      let dom = null
      try {
        // 参数为 canvasDom
        dom = imgCroppingHandler(createCanvasDomByImg(item.img), parm)

        // 参数为 Image
        // dom = imgCroppingHandler(item.img, parm)

        // 参数为 canvasImgData
        // let d = createCanvasDomByImg(item.img)
        // let ctx = d.getContext('2d')
        // let imgData = ctx.getImageData(0, 0, d.width, d.height)
        // dom = imgCroppingHandler(imgData, parm)

        item.success = true
      } catch (e) {
        console.log('自动裁剪失败:', e)
        item.success = false
        return
      }
      let pDom = this.$refs['canvasDiv' + item.id][0]
      let sf = 990 / dom.width
      dom.style.transform = 'scale(' + sf + ')'
      dom.style.transformOrigin = '0 0'
      item.d['max-height'] = dom.height * sf + 'px'
      item.d['min-height'] = dom.height * sf + 'px'
      pDom.appendChild(dom)
    },
    dzcj (item) {
      this.chooseData = item
      this.openDialog = true
    },
    formSubmit (data, a) {
      data.minColor = data.minColor ? Number(data.minColor) : null
      data.sameColorSub = data.sameColorSub ? Number(data.sameColorSub) : null
      this.openDialog = false
      a()
      this.cj(this.chooseData, data)
    },
    async loadData () {
      let i = 0
      while (i < this.imgList.length) {
        await this.loadFile(this.imgList[i])
        i += 1
      }
    },
    async loadFile (item) {
      let fileData = await fileRequest.download({
        id: item.id
      })
      const src = await new Promise(resolve => {
        let reader = new FileReader()
        reader.readAsDataURL(fileData)
        reader.onload = () => resolve(reader.result)
      })
      item.src = src
      let img = new Image()
      img.src = item.src
      img.onload = function() {
        item.width = img.width
        item.height = img.height
        item.img = img
      }
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        label: '方式',
        key: 'type',
        selectDatas: [{key: 'minColor', label: '最小颜色值'}, {key: 'exactSameColor', label: '准确相似颜色'}, {key: 'sameColor', label: '相似颜色'}, {key: 'ignoreBlackWhite', label: '忽略黑白'}]
      },
      {
        type: 'input',
        label: '最小颜色值',
        key: 'minColor'
      },
      {
        type: 'input',
        label: '相似颜色差',
        key: 'sameColorSub'
      }]
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style scoped lang="less">
.test {
  background: #000;
  display:block;
}
.img-item {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
}
.c-s {
  position: relative;
  width: 100px;
}
.c-s-1 {
  position: absolute;
  background: red;
  top: 0;
  left: 0;
  transform-origin: 0 0;
}
.c-s-2 {
  position: absolute;
  background: red;
  top: 0;
  transform-origin: 0 0;
}
</style>
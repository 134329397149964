class ImgCropping {
  constructor ({imgData, width, height}) {
    this.imgData = imgData
    this.width = width
    this.height = height
    // minColor | sameColor | exactSameColor | ignoreBlackWhite
    this.testBlankType = null
    this.minColor = null
    this.sameColorSub = null
    this.compareColor = null
    this.minJd = 3
    this.spotLength = 30
    this.points = {
      top: [0, 0],
      bottom: [0, 0],
      right: [0, 0],
      left: [0, 0],
      topLeft: [0, 0],
      topRight: [0, 0],
      bottomLeft: [0, 0],
      bottomRight: [0, 0],
    }
    // a * x + b = y
    this.line = {
      top: [0, 0],
      bottom: [0, 0],
      right: [0, 0],
      left: [0, 0],
    }
    this.result = {
      topLeftPoint: [0, 0],
      topRightPoint: [0, 0],
      bottomLeftPoint: [0, 0],
      bottomRightPoint: [0, 0],
      width: 0,
      height: 0,
      angle: 0
    }
  }
  compute (parm) {
    if (parm) {
      this.computeTest(parm)
    } else {
      this.tryCompute()
    }
    this.computeResult()
    console.log('裁剪成功')
    return this.result
  }
  computeTest ({type, sameColorSub, minColor}) {
    this.testBlankType = type
    this.sameColorSub = sameColorSub
    this.minColor = minColor
    if (type === 'exactSameColor' && !sameColorSub) {
      if (!this.computeByExactSameColor()) {
        throw new Error('精确相似颜色自动裁剪失败')
      }
    }  else if (type === 'sameColor' && !sameColorSub) {
      if (!this.computeBySameColor()) {
        throw new Error('相似颜色自动裁剪失败')
      }
    } else if (type === 'minColor' && !minColor) {
      if (!this.computeByMinColor()) {
        throw new Error('最小颜色自动裁剪失败')
      }
    } else if (type === 'ignoreBlackWhite' && !sameColorSub) {
      if (!this.computeByIgnoreBlackWhite()) {
        throw new Error('忽略黑白自动裁剪失败')
      }
    } else {
      this.computePoints()
      this.computeLine()
      if (!this.checkLine()) {
        throw new Error('线的校验未通过:' + type + ':' + minColor + ' | ' + sameColorSub)
      }
    }
  }
  tryCompute () {
    this.minJd = 1
    while (this.minJd < 3) {
      if (this.computeByExactSameColor()) {
        return
      }
      if (this.computeByMinColor()) {
        return
      }
      if (this.computeByIgnoreBlackWhite()) {
        return
      }
      if (this.computeBySameColor()) {
        return
      }
      this.minJd += 1
    }
    this.minJd = 1
    while (this.minJd < 3) {
      if (this.computeByMinColor2()) {
        return
      }
      if (this.computeByIgnoreBlackWhite2()) {
        return
      }
      this.minJd += 1
    }
    throw new Error('自动裁剪失败')
  }
  computeByMinColor () {
    this.testBlankType = 'minColor'
    // let minColorList = [200, 210, 190, 180, 170, 160, 220, 230, 240, 250]
    let minColorList = [230, 220, 210, 200]
    let ok = false
    let i = 0
    while (!ok && i < minColorList.length) {
      try {
        this.minColor = minColorList[i]
        this.computePoints()
        this.computeLine()
        if (!this.checkLine()) {
          throw new Error('线的校验未通过 minColor:' + this.minColor)
        }
        ok = true
        // console.log('最小颜色成功:' + minColorList[i])
      } catch (e) {
        // console.log(e)
        ok = false
      }
      i += 1
    }
    return ok
  }
  computeByMinColor2 () {
    this.testBlankType = 'minColor'
    let minColorList = [240, 190, 180, 170, 160]
    let ok = false
    let i = 0
    while (!ok && i < minColorList.length) {
      try {
        this.minColor = minColorList[i]
        this.computePoints()
        this.computeLine()
        if (!this.checkLine()) {
          throw new Error('线的校验未通过 minColor:' + this.minColor)
        }
        ok = true
        // console.log('最小颜色成功:' + minColorList[i])
      } catch (e) {
        // console.log(e)
        ok = false
      }
      i += 1
    }
    return ok
  }
  computeBySameColor () {
    this.testBlankType = 'sameColor'
    let sameColorSub = 7
    let ok = false
    while (!ok && sameColorSub < 20) {
      try {
        this.sameColorSub = sameColorSub
        this.computePoints()
        this.computeLine()
        if (!this.checkLine()) {
          throw new Error('线的校验未通过 sameColor:' + sameColorSub)
        }
        ok = true
        // console.log('最小颜色差值:' + sameColorSub)
      } catch (e) {
        // console.log(e)
        ok = false        
      }
      sameColorSub = sameColorSub + 1
    }
    return ok
  }
  computeByExactSameColor () {
    this.testBlankType = 'exactSameColor'
    let sameColorSub = 7
    let ok = false
    while (!ok && sameColorSub < 20) {
      try {
        this.sameColorSub = sameColorSub
        this.computePoints()
        this.computeLine()
        if (!this.checkLine()) {
          throw new Error('线的校验未通过 exactSameColor:' + sameColorSub)
        }
        ok = true
        // console.log('精确最小颜色差值:' + sameColorSub)
      } catch (e) {
        // console.log(e)
        ok = false        
      }
      sameColorSub = sameColorSub + 1
    }
    return ok
  }
  computeByIgnoreBlackWhite () {
    this.testBlankType = 'ignoreBlackWhite'
    let sameColorSub = 6
    let ok = false
    while (!ok && sameColorSub < 20) {
      try {
        this.sameColorSub = sameColorSub
        this.computePoints()
        this.computeLine()
        if (!this.checkLine()) {
          throw new Error('线的校验未通过 ignoreBlackWhite:' + sameColorSub)
        }
        ok = true
        // console.log('忽略黑白颜色差值:' + sameColorSub)
      } catch (e) {
        // console.log(e)
        ok = false        
      }
      sameColorSub = sameColorSub + 1
    }
    return ok
  }
  computeByIgnoreBlackWhite2 () {
    this.testBlankType = 'ignoreBlackWhite'
    let sameColorSub = 1
    let ok = false
    while (!ok && sameColorSub < 6) {
      try {
        this.sameColorSub = sameColorSub
        this.computePoints()
        this.computeLine()
        if (!this.checkLine()) {
          throw new Error('线的校验未通过 ignoreBlackWhite:' + sameColorSub)
        }
        ok = true
        console.log('忽略黑白颜色差值:' + sameColorSub)
      } catch (e) {
        // console.log(e)
        ok = false        
      }
      sameColorSub = sameColorSub + 1
    }
    return ok
  }
  computeLine () {
    this.computeTopLine()
    this.computeLeftLine()
    this.computeRightLine()
    this.computeBottomLine()
  }
  computePoints () {
    this.points.top = this.computeTop()
    this.points.bottom = this.computeBottom()
    this.points.left = this.computeLeft()
    this.points.right = this.computeRight()
    if (this.points.left[1] < this.points.right[1]) {
      this.points.topLeft = this.points.left
      this.points.topRight = this.points.top
      this.points.bottomLeft = this.points.bottom
      this.points.bottomRight = this.points.right
    } else {
      this.points.topLeft = this.points.top
      this.points.topRight = this.points.right
      this.points.bottomLeft = this.points.left
      this.points.bottomRight = this.points.bottom
    }
  }
  computeResult () {
    this.result.topLeftPoint = this.computePointByLine(this.line.top, this.line.left)
    this.result.topRightPoint = this.computePointByLine(this.line.top, this.line.right)
    this.result.bottomLeftPoint = this.computePointByLine(this.line.bottom, this.line.left)
    this.result.bottomRightPoint = this.computePointByLine(this.line.bottom, this.line.right)
    this.result.width = this.computeLengthByPoint(this.result.topLeftPoint, this.result.topRightPoint)
    // 这个可以优化 不是正正的90度有偏差
    this.result.height = this.computeLengthByPoint(this.result.topLeftPoint, this.result.bottomLeftPoint)
    this.result.angle = -Math.atan(this.line.top[0])
    if (Math.max(this.result.width, this.result.height) / Math.min(this.result.width, this.result.height) > 10) {
      throw new Error('自动裁剪失败,结果长宽比值异常')
    }
  }
  checkLine () {
    let maxTan = Math.tan(Math.PI / 180 * this.minJd)
    let zjMaxTan = Math.tan(Math.PI / 180 * (90 - this.minJd))
    let pass = this.line.top[0] * this.line.bottom[0] !== -1
    pass = pass && Math.abs((this.line.top[0] - this.line.bottom[0]) / (1 +  this.line.top[0] * this.line.bottom[0])) < maxTan
    pass = pass && this.line.left[0] * this.line.right[0] !== -1
    pass = pass && Math.abs((this.line.left[0] - this.line.right[0]) / (1 +  this.line.left[0] * this.line.right[0])) < maxTan
    if (pass) {
      pass = this.line.top[0] * this.line.left[0] === -1
      let zjTan = Math.abs((this.line.top[0] - this.line.left[0]) / (1 +  this.line.top[0] * this.line.left[0]))
      pass = pass || zjTan > zjMaxTan
    }
    return pass
  }
  getCanvasParm () {
    let minx = Math.min(this.result.topLeftPoint[0], this.result.bottomLeftPoint[0])
    let miny = Math.min(this.result.topLeftPoint[1], this.result.topRightPoint[1])
    let rotatedPoint = this.getRotatedPoint([minx, miny], this.result.topLeftPoint, this.result.bottomLeftPoint, this.result.topRightPoint, this.result.angle)
    return {
      sourceWidth: this.width - minx,
      sourceHeight: this.height - miny,
      angle: this.result.angle,
      imgStartPoint: [minx, miny],
      width: this.result.width,
      height: this.result.height,
      croppingPoint: rotatedPoint[0],
      translatePoint: rotatedPoint[1],
      topLeftPoint: this.result.topLeftPoint
    }
  }
  getRotatedPoint (startPoint, topLeftPoint, bottomLeftPoint, topRightPoint, angle) {
    let newTopLeftPoint = [0, 0]
    let translatePoint = [0, 0]
    if (angle > 0) {
      let l = topLeftPoint[1] - startPoint[1]
      let l1 = topRightPoint[0] - startPoint[0]
      if (l1 > l) {
        newTopLeftPoint[1] = l1 * Math.sin(angle)
      } else {
        newTopLeftPoint[1] = l * Math.cos(angle)
      }
      translatePoint = [l * Math.sin(angle), 0]
      newTopLeftPoint[0] = 0
    } else {
      let l = topLeftPoint[0] - startPoint[0]
      let l1 = bottomLeftPoint[1] - topLeftPoint[1]
      if (l1 > l) {
        newTopLeftPoint[0] = l1 * Math.sin(-angle)
      } else {
        newTopLeftPoint[0] = l * Math.cos(-angle)
      }
      translatePoint = [0, l * Math.sin(-angle)]

      newTopLeftPoint[1] = 0
    }
    return [newTopLeftPoint, translatePoint]
  }
  computePointByLine (l1, l2) {
    // x = (b2 - b1) / (a1 - a2)
    let x = (l1[1] - l2[1]) / (l2[0] - l1[0])
    let y = l1[0] * x + l1[1]
    return [x, y]
  }
  computeLineByPoint (p1, p2) {
    // a * x + b = y
    if (p2[0] - p1[0] === 0) {
      return [0, p1[1]]
    }
    let a = (p2[1] - p1[1]) / (p2[0] - p1[0])
    let b = p1[1] - p1[0] * a
    return [a, b]
  }
  computeLengthByPoint (p1, p2) {
    let x = p1[0] - p2[0]
    let y = p1[1] - p2[1]
    return Math.sqrt(x * x + y * y)
  }
  computeTopLine () {
    let minx = this.points.topLeft[0]
    let maxx = this.points.topRight[0]
    if (maxx - minx < 3) {
      throw new Error('Error computeTopLine')
    }
    let l = Number(((maxx - minx) / 3).toFixed(0))
    let x1 = minx + l
    let x2 = x1 + l
    let p1 = null
    let p2 = null
    let j = 0
    this.compareColor = this.getColor(x1, j)
    while (j < this.height) {
      if (!p1 && !this.isBlank(x1, j)) {
        p1 = [x1, j]
        break
      }
      j += 1
    }
    j = 0
    this.compareColor = this.getColor(x2, j)
    while (j < this.height) {
      if (!p2 && !this.isBlank(x2, j)) {
        p2 = [x2, j]
        break
      }
      j += 1
    }
    if (p1 === null || p2 === null) {
      throw new Error('Error computeTopLine p')
    }
    this.line.top = this.computeLineByPoint(p1, p2)
  }
  computeBottomLine () {
    let minx = this.points.bottomLeft[0]
    let maxx = this.points.bottomRight[0]
    if (maxx - minx < 3) {
      throw new Error('Error computeBottomLine')
    }
    let l = Number(((maxx - minx) / 3).toFixed(0))
    let x1 = minx + l
    let x2 = x1 + l
    let p1 = null
    let p2 = null
    let j = this.height - 1
    this.compareColor = this.getColor(x1, j)
    while (j > 0) {
      if (!p1 && !this.isBlank(x1, j)) {
        p1 = [x1, j]
        break
      }
      j -= 1
    }
    j = this.height - 1
    this.compareColor = this.getColor(x2, j)
    while (j > 0) {
      if (!p2 && !this.isBlank(x2, j)) {
        p2 = [x2, j]
        break
      }
      j -= 1
    }
    if (p1 === null || p2 === null) {
      throw new Error('Error computeTopLine p')
    }
    this.line.bottom = this.computeLineByPoint(p1, p2)
  }
  computeLeftLine () {
    let miny = this.points.topLeft[1]
    let maxy = this.points.bottomLeft[1]
    let minx = this.points.topLeft[0] > this.points.bottomLeft[0] ? this.points.bottomLeft[0] : this.points.topLeft[0]
    if (maxy - miny < 3) {
      throw new Error('Error computeLeftLine')
    }
    let l = Number(((maxy - miny) / 3).toFixed(0))
    let y1 = miny + l
    let y2 = y1 + l
    let p1 = null
    let p2 = null
    let i = minx
    this.compareColor = this.getColor(i, y1)
    while (i < this.width) {
      if (!p1 && !this.isBlank(i, y1)) {
        p1 = [i, y1]
        break
      }
      i += 1
    }
    i = minx
    this.compareColor = this.getColor(i, y2)
    while (i < this.width) {
      if (!p2 && !this.isBlank(i, y2)) {
        p2 = [i, y2]
        break
      }
      i += 1
    }
    if (p1 === null || p2 === null) {
      throw new Error('Error computeTopLine p')
    }
    this.line.left = this.computeLineByPoint(p1, p2)
  }
  computeRightLine () {
    let miny = this.points.topRight[1]
    let maxy = this.points.bottomRight[1]
    let maxx = this.points.topRight[0] > this.points.bottomRight[0] ? this.points.topRight[0] : this.points.bottomRight[0]
    if (maxy - miny < 3) {
      throw new Error('Error computeRightLine p')
    }
    let l = Number(((maxy - miny) / 3).toFixed(0))
    let y1 = miny + l
    let y2 = y1 + l
    let p1 = null
    let p2 = null
    let i = maxx
    this.compareColor = this.getColor(i, y1)
    while (i > 0) {
      if (!p1 && !this.isBlank(i, y1)) {
        p1 = [i, y1]
        break
      }
      i -= 1
    }
    i = maxx
    this.compareColor = this.getColor(i, y2)
    while (i > 0) {
      if (!p2 && !this.isBlank(i, y2)) {
        p2 = [i, y2]
        break
      }
      i -= 1
    }
    if (p1 === null || p2 === null) {
      throw new Error('Error computeTopLine p')
    }
    this.line.right = this.computeLineByPoint(p1, p2)
  }
  computeTop () {
    let j = 0
    while (j < this.height) {
      let i = 0
      this.compareColor = this.getColor(i, j)
      while (i < this.width) {
        if (!this.isBlank(i, j)) {
          return [i, j]
        }
        i += 1
      }
      j += 1
    }
    throw new Error('Error computeTop')
  }
  computeBottom () {
    let j = this.height - 1
    while (j > 0) {
      let i = this.width - 1
      this.compareColor = this.getColor(i, j)
      while (i > 0) {
        if (!this.isBlank(i, j)) {
          return [i, j]
        }
        i -= 1
      }
      j -= 1
    }
    throw new Error('Error computeBottom')
  }
  computeRight () {
    let i = this.width - 1
    while (i > 0) {
      let j = 0
      this.compareColor = this.getColor(i, j)
      while (j < this.height) {
        if (!this.isBlank(i, j)) {
          return [i, j]
        }
        j += 1
      }
      i -= 1
    }
    throw new Error('Error computeRight')
  }
  computeLeft () {
    let i = 0
    while (i < this.width) {
      let j = this.height - 1
      this.compareColor = this.getColor(i, j)
      while (j > 0) {
        if (!this.isBlank(i, j)) {
          return [i, j]
        }
        j -= 1
      }
      i += 1
    }
    throw new Error('computeRight')
  }
  isBlank (x, y) {
    if (this.testBlankType === 'minColor') {
      let is = this.isBlankColor(x,y)
      if (!is) {
        is = this.isBlackSpot(x, y)
      }
      return is
    } else if (this.testBlankType === 'ignoreBlackWhite') {
      let is = this.isBlackWhiteColor(x,y)
      if (!is) {
        is = this.isBlackWhiteSpot(x, y)
      }
      return is
    } else {
      return this.isSame(x, y)
    }
  }
  isBlackWhiteSpot (x, y) {
    let py = this.spotLength
    return this.isBlackWhiteColor(x, y - py) && this.isBlackWhiteColor(x + py, y) && this.isBlackWhiteColor(x, y + py) && this.isBlackWhiteColor(x - py, y)
  }
  isBlackSpot (x, y) {
    let py = this.spotLength
    return this.isBlankColor(x, y - py) && this.isBlankColor(x + py, y) && this.isBlankColor(x, y + py) && this.isBlankColor(x - py, y)
  }
  isBlankColor (x, y) {
    let color = this.getColor(x, y)
    if (color === null) {
      return true
    }
    let is = true
    let i = 0
    while (is && i < color.length) {
      is = color[i] > this.minColor
      i += 1
    }
    return is
  }
  isBlackWhiteColor (x, y) {
    let color = this.getColor(x, y)
    if (color === null) {
      return true
    }
    let b1 = color[0] - color[1]
    let b2 = color[0] - color[2]
    return Math.sqrt(b1 * b1) < this.sameColorSub && Math.sqrt(b2 * b2) < this.sameColorSub
  }
  getColor (x, y) {
    if (x < 0 || x > this.width - 1 || y > this.height -1 || y < 0) {
      return null
    }
    let i = (x + this.width * y) * 4
    return [this.imgData[i], this.imgData[i + 1], this.imgData[i + 2]]
  }
  isSame (x, y) {
    let is = this.isSameColor(x,y)
    let isDian = false
    if (!is) {
      is = this.isSpot(x, y)
      isDian = is
    }
    if (!isDian) {
      this.compareColor = this.getColor(x, y)
    }
    return is
  }
  isSpot (x, y) {
    let py = this.spotLength
    return this.isSameColor(x, y - py) && this.isSameColor(x + py, y) && this.isSameColor(x, y + py) && this.isSameColor(x - py, y)
  }
  isSameColor (x, y) {
    let color = this.getColor(x, y)
    if (color === null) {
      return true
    }
    let isSame = true
    let j = 0
    while (isSame && j < this.compareColor.length) {
      isSame = (color[j] > this.compareColor[j] - this.sameColorSub) && (this.testBlankType === 'sameColor' || (color[j] < this.compareColor[j] + this.sameColorSub))
      j += 1
    }
    return isSame
  }
}

export default ImgCropping